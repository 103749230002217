import React from 'react';

const Index = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="350" height="350" viewBox="0 0 350 350" fill="none">
        <path d="M90.7407 175C90.7407 200.057 70.4277 220.37 45.3704 220.37C20.313 220.37 0 200.057 0 175C0 149.943 20.313 129.63 45.3704 129.63C70.4277 129.63 90.7407 149.943 90.7407 175Z" fill="url(#paint0_linear_1224_6727)"/>
        <path d="M90.7407 304.63C90.7407 329.687 70.4277 350 45.3704 350C20.313 350 0 329.687 0 304.63C0 279.572 20.313 259.259 45.3704 259.259C70.4277 259.259 90.7407 279.572 90.7407 304.63Z" fill="url(#paint1_linear_1224_6727)"/>
        <path d="M220.37 45.3704C220.37 70.4277 200.057 90.7407 175 90.7407C149.943 90.7407 129.63 70.4277 129.63 45.3704C129.63 20.313 149.943 0 175 0C200.057 0 220.37 20.313 220.37 45.3704Z" fill="url(#paint2_linear_1224_6727)"/>
        <path d="M90.7407 45.3704C90.7407 70.4277 70.4277 90.7407 45.3704 90.7407C20.313 90.7407 0 70.4277 0 45.3704C0 20.313 20.313 0 45.3704 0C70.4277 0 90.7407 20.313 90.7407 45.3704Z" fill="url(#paint3_linear_1224_6727)"/>
        <path d="M198.333 301.389C198.333 313.918 188.177 324.074 175.648 324.074C163.119 324.074 152.963 313.918 152.963 301.389C152.963 288.86 163.119 278.704 175.648 278.704C188.177 278.704 198.333 288.86 198.333 301.389Z" fill="url(#paint4_linear_1224_6727)"/>
        <path d="M350 45.3704C350 70.4277 329.687 90.7407 304.63 90.7407C279.572 90.7407 259.259 70.4277 259.259 45.3704C259.259 20.313 279.572 0 304.63 0C329.687 0 350 20.313 350 45.3704Z" fill="url(#paint5_linear_1224_6727)"/>
        <path d="M350 304.63C350 329.687 329.687 350 304.63 350C279.572 350 259.259 329.687 259.259 304.63C259.259 279.572 279.572 259 304.63 259C329.687 259 350 279.572 350 304.63Z" fill="url(#paint6_linear_1224_6727)"/>
        <path d="M220.37 175C220.37 200.057 200.057 220.37 175 220.37C149.943 220.37 129.63 200.057 129.63 175C129.63 149.943 149.943 129.63 175 129.63C200.057 129.63 220.37 149.943 220.37 175Z" fill="url(#paint7_linear_1224_6727)"/>
        <path d="M350 175C350 200.057 329.687 220.37 304.63 220.37C279.572 220.37 259.259 200.057 259.259 175C259.259 149.943 279.572 129.63 304.63 129.63C329.687 129.63 350 149.943 350 175Z" fill="url(#paint8_linear_1224_6727)"/>
        <path d="M84.2888 198.333C90.2441 190.509 100.26 185.46 110.833 185.46C121.407 185.46 130.804 190.509 136.759 198.333C132.713 191.501 130.39 183.523 130.39 175C130.39 166.477 132.713 158.499 136.759 151.667C130.804 159.491 121.407 164.54 110.833 164.54C100.26 164.54 90.2441 159.491 84.2888 151.667C88.3347 158.499 90.7407 166.477 90.7407 175C90.7407 183.523 88.3347 191.501 84.2888 198.333Z" fill="url(#paint9_linear_1224_6727)"/>
        <path d="M213.889 198.333C219.844 190.509 229.242 185.46 239.815 185.46C250.388 185.46 259.785 190.509 265.741 198.333C261.695 191.501 259.372 183.523 259.372 175C259.372 166.477 261.695 158.499 265.741 151.667C259.785 159.491 250.388 164.54 239.815 164.54C229.242 164.54 219.844 159.491 213.889 151.667C217.935 158.499 220.258 166.477 220.258 175C220.258 183.523 217.935 191.501 213.889 198.333Z" fill="url(#paint10_linear_1224_6727)"/>
        <path d="M84.2593 68.7037C90.2145 60.8791 99.6119 55.8304 110.185 55.8304C120.758 55.8304 130.156 60.8791 136.111 68.7037C132.065 61.8715 129.742 53.893 129.742 45.3704C129.742 36.8477 132.065 28.8692 136.111 22.037C130.156 29.8617 120.758 34.9104 110.185 34.9104C99.6119 34.9104 90.2145 29.8617 84.2593 22.037C88.3052 28.8692 90.6283 36.8477 90.6283 45.3704C90.6283 53.893 88.3052 61.8715 84.2593 68.7037Z" fill="url(#paint11_linear_1224_6727)"/>
        <path d="M213.889 68.7037C219.844 60.8791 229.242 55.8304 239.815 55.8304C250.388 55.8304 259.785 60.8791 265.741 68.7037C261.695 61.8715 259.372 53.893 259.372 45.3704C259.372 36.8477 261.695 28.8692 265.741 22.037C259.785 29.8617 250.388 34.9104 239.815 34.9104C229.242 34.9104 219.844 29.8617 213.889 22.037C217.935 28.8692 220.258 36.8477 220.258 45.3704C220.258 53.893 217.935 61.8715 213.889 68.7037Z" fill="url(#paint12_linear_1224_6727)"/>
        <path d="M169.5 219.5C184.931 223.865 211.66 231.945 229.233 249.518C246.806 267.091 257.135 293.069 261.5 308.5C259.607 296.945 262.74 285.082 268.767 279.056C274.793 273.029 300 259.236 304.63 259C289.199 254.635 261.599 252.299 244.026 234.726C226.453 217.153 224.405 194.931 220.04 179.5C221.933 191.055 210.152 203.592 204.125 209.618C198.099 215.644 181.055 221.393 169.5 219.5Z" fill="url(#paint13_linear_1224_6727)"/>
        <path d="M68.7037 265.853C60.8791 259.898 55.8304 250.5 55.8304 239.927C55.8304 229.354 60.8791 219.957 68.7037 214.001C61.8715 218.047 53.893 220.37 45.3704 220.37C36.8477 220.37 28.8692 218.047 22.037 214.001C29.8617 219.957 34.9104 229.354 34.9104 239.927C34.9104 250.5 29.8617 259.898 22.037 265.853C28.8692 261.807 36.8477 259.484 45.3704 259.484C53.893 259.484 61.8715 261.807 68.7037 265.853Z" fill="url(#paint14_linear_1224_6727)"/>
        <path d="M281.944 84.2593C289.769 90.2145 294.818 99.6119 294.818 110.185C294.818 120.758 289.769 130.156 281.944 136.111C288.777 132.065 296.755 129.742 305.278 129.742C313.8 129.742 321.18 133.454 328.012 137.5C320.188 131.545 315.738 120.758 315.738 110.185C315.738 99.6119 320.188 90.2145 328.012 84.2593C321.18 88.3052 313.8 90.6283 305.278 90.6283C296.755 90.6283 288.777 88.3052 281.944 84.2593Z" fill="url(#paint15_linear_1224_6727)"/>
        <path d="M22.037 84.2593C29.8617 90.2145 34.9104 99.6119 34.9104 110.185C34.9104 120.758 29.8617 130.156 22.037 136.111C28.8692 132.065 36.8477 129.742 45.3704 129.742C53.893 129.742 61.8715 132.065 68.7037 136.111C60.8791 130.156 55.8304 120.758 55.8304 110.185C55.8304 99.6119 60.8791 90.2145 68.7037 84.2593C61.8715 88.3052 53.893 90.6283 45.3704 90.6283C36.8477 90.6283 28.8692 88.3052 22.037 84.2593Z" fill="url(#paint16_linear_1224_6727)"/>
        <defs>
          <linearGradient id="paint0_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint1_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint2_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint3_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint4_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint5_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint6_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint7_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint8_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint9_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint10_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint11_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint12_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint13_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint14_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint15_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
          <linearGradient id="paint16_linear_1224_6727" x1="-2.84673e-06" y1="381" x2="343.214" y2="16.3665" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9730"/>
            <stop offset="0.5" stopColor="#FFA444"/>
            <stop offset="1" stopColor="#FFBA61"/>
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default Index;